<template>
  <el-container class="article_manage" v-show="!editing">
    <el-main>
      <el-scrollbar class="article_table">
        <div class="table_header">
          <table>
            <colgroup>
              <col class="col_id">
              <col class="col_title">
              <col class="col_writer">
              <col class="col_release_date">
              <col class="col_edit">
            </colgroup>
            <thead>
            <tr>
              <th colspan="1" rowspan="1">
                <div>ID</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>标题</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>作者</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>发布时间</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>操作</div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in article_list" :key="item.id">
              <th colspan="1" rowspan="1">
                <div>{{ item.id }}</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>{{ item.title }}</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>{{ item.writer }}</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>{{ item.release_date }}</div>
              </th>
              <th colspan="1" rowspan="1">
                <div>
                  <el-button-group>
                    <el-button class="ri-edit-2-line edit_button" @click="edit(item.id)"></el-button>
                    <el-button class="ri-delete-bin-line delete_button"
                               @click="delete_article(item.id,index)"></el-button>
                  </el-button-group>
                </div>
              </th>
            </tr>
            </tbody>
          </table>
        </div>
      </el-scrollbar>
      <!--      </el-scrollbar>-->
    </el-main>
    <el-aside style="margin-block: 20px" width="270px">
      <div class="info">共有文章<br>{{ article_list.length }}篇</div>
      <el-button class="upload_button" @click="edit('')">发表新文章</el-button>
    </el-aside>
  </el-container>
  <article_edit v-show="editing" @change_editing="change_editing" :id="article_id"></article_edit>
</template>

<script>
import article_edit from "@/components/manage/article_edit";

export default {
  name: "Article_manage",
  components: {
    article_edit
  },
  data() {
    return {
      article_id: '',
      article_list: [],
      file_list: [],
      editing: false,
      width: 0
    }
  },
  methods: {
    token() {
      return {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    },
    change_editing() {
      this.editing = !this.editing;
    },
    edit(info) {
      this.article_id = info
      this.change_editing()
    },
    delete_article(article_id, index) {
      let access_token = localStorage.getItem('token')
      if (access_token) {
        this.$axios.post('/api/delete_article', {
          id: article_id
        }, {
          headers: {
            'Authorization': 'Bearer ' + access_token
          }
        }).then(res => {
          this.$ElNotification({
            title: res.data,
            type: 'success'
          })
          this.article_list.splice(index, 1);
        }).catch(err => {
          console.error(err)
          this.$ElNotification({
            title: '权限验证失败',
            type: 'error'
          })
        })
      } else {
        this.$ElNotification({
          title: '未登录用户',
          type: 'error'
        })
      }
    }
  },
  created() {
    this.$axios.get('/api/article_list').then(res => {
      this.article_list = res.data
    })

  }
}
</script>

<style scoped>
.article_manage {
  width: 100%;
  height: 100%;
  margin: auto;
}

.article_table {
  height: 100%;
  background-color: #ffffff;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  position: sticky;
  background-color: #FFF;
  top: 0;
}

tr {
  border-bottom: 1px solid #ebeef5;
  height: 50px;
}

tbody th {
  font-weight: normal;
}

th > div {
  padding-inline: 15px;
  text-align: left;
}

.table_header {
}

.table_body {

}

.edit_button {
  border: none;
  width: 34px;
}

.delete_button {
  width: 34px;
  border: none;
}

.info {
  display: flex;
  width: 250px;
  height: 150px;
  background-color: #fdfdfd;
  border-radius: 15px;
  margin-bottom: 10px;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
}

.upload_button {
  width: 250px;
  height: 50px;
  font-size: 20px;
  border-radius: 15px;
}
</style>