<template>
  <div class="article_edit">
    <div class="title">
      <input class="input_box" placeholder="请输入标题..." v-model="title">
      <el-button-group class="button_group" size="large">
        <el-button @click="upload">{{ id ? '保存' : '发表' }}</el-button>
        <el-button @click="close_edit">返回</el-button>
      </el-button-group>
    </div>
    <Editor v-model="content" :init="init" api-key="4v5bbdqn28zhutthbgm1zk0bvmuo2vk51ewq6cyv5hl2j3rs"></Editor>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "article_edit",
  components: {
    Editor
  },
  data() {
    return {
      init: {
        plugins: 'preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap nonbreaking insertdatetime advlist lists wordcount autosave anchor pagebreak',
        toolbar: "code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link codesample | alignleft aligncenter alignright alignjustify outdent indent lineheight formatpainter | \
                  styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                  table image media charmap hr pagebreak insertdatetime | bdmap fullscreen preview",
        contextmenu: "cut copy paste pastetext",
        toolbar_mode: "wrap",
        // toolbar_mode: 'floating',
        autosave_restore_when_empty: true,
        autosave_retention: '30m',
        language: 'zh-Hans',
        // language_url: '/langs/zh-Hans.js',
        language_url: 'https://mycloud-1257897713.cos.ap-shanghai.myqcloud.com/resources/tinymce/zh-Hans.js',
        branding: false,
        height: 'calc(100% - 40px)'

      },
      content: '',
      title: ''
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    close_edit() {
      this.$emit('change_editing')
    },
    upload() {
      if (!(this.content || this.title)) {
        this.$ElNotification({
          title: '文章信息不全',
          type: 'info'
        })
      } else {
        let access_token = localStorage.getItem('token')
        if (access_token) {
          this.$axios.post('/api/article_upload', {
            id: this.id,
            title: this.title,
            content: this.content,
          }, {
            headers: {
              'Authorization': 'Bearer ' + access_token
            }
          }).then(res => {
            this.$ElNotification({
              title: res.data,
              type: 'success'
            })
          }).catch(err => {
            console.log(err.response)
          })
        } else {
          this.$ElNotification({
            title: '未登录用户',
            type: 'error'
          })
        }
      }
    },
    async getArticle(article_id) {
      let state = null
      try {
        let res = await this.$axios.get('/api/article', {
          params: {
            id: article_id
          }
        })
        this.content = res.data.content
        this.title = res.data.title
        state = true
        this.$ElNotification({
          title: '文章加载完成',
          type: 'success'
        })
      } catch (reason) {
        console.log(reason)
        state = false
      }
      return state
    }
  },
  watch: {
    id(newVal) {
      this.title = ''
      this.content = ''
      if (this.id) {
        this.$ElNotification({
          title: '文章加载中...',
          type: 'info'
        })
        this.getArticle(newVal).then(res => {
          console.log('editor文章请求结果：' + res)
        })
      }
    }
  }
}
</script>

<style scoped>
.article_edit {
  width: 100%;
  height: 100%;
}

.title {
  height: 40px;
  background-color: #ffffff;
}

.input_box {
  border: none;
  outline: none;
  /*background-color: #e7e7e7;*/
  border-radius: 5px;
  height: 38px;
  width: 500px;
  margin: 0 0 0 5px;
  font-size: 18px;
}

.button_group {
  float: right;
  /*margin: 1px 10px 0 0;*/
}

.button_group > button {
  height: 40px;
  width: 100px;
  border: none;
}

.article_edit textarea {
  display: none;
}
</style>